<template>
    <div>
        <center>
            <h3>Import Excel</h3>
        </center>
        <br>
        <form v-on:submit.prevent="addContact">
            <label for="">File CSV*</label>
            <input type="file" class="form-control" name="file" @change="readFile" accept=".csv" required />
            <br />
            <p>
                File contoh dapat diunduh
                <a style="display: contents" href="/excel/contohcsv.csv" download>
                    disini
                </a>

            </p>
            <br />
            <label>Device</label>
            <select v-model="form.device" class="form-control" required>
                <option :value="null_value" selected disabled>
                    == PILIH DEVICE ==
                </option>
                <option v-for="option in devices" :key="option.id" :value="option.id">{{ option.name }}
                    ({{ option.status }})</option>
            </select>
            <br />
            <button v-if="csvParseLoading"
                class="btn btn-spinner btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                disabled>
                <div class="spinner-border spinner" role="status"></div>
                Sedang memproses data csv
            </button>
            <button v-else-if="!csvImportReady && !csvParseLoading" class="btn btn-danger btn-block" disabled>
                Silahkan Upload CSV Terlebih Dahulu
            </button>
            <button v-else-if="csvImportReady && !csvParseLoading" type="submit" class="btn btn-primary btn-block">
                Upload
            </button>
            <br>
            <div v-if="csvImportReady">
                <h5 class="text-center">== PREVIEW ==</h5>
                <ImportPreview v-if="csvImportReady" :rows='importData' />
            </div>
        </form>
    </div>
</template>

<script>
    import ImportPreview from "@/components/forms/autoreply/ImportPreview.vue";

    export default {
        components: {
            ImportPreview,
        },
        props: ["devices"],
        data() {
            return {
                importData: [],
                form: {
                    name: "",
                    number: "",
                    variable: [],
                    device: null,
                },
                null_value: null,
                csvParseLoading: false,
                csvImportReady: false
            };
        },
        mounted() {

        },
        methods: {
            async readFile() {
                let file = event.target.files[0];
                if (file.type != "text/csv") {
                    this.$toast.error("File harus .csv")
                    return
                }

                this.importData = []
                this.csvImportReady = false
                this.csvParseLoading = true

                try {
                    let result = await this.papaParseAsync(file);
                    await this.arrayToData(result)

                    if (this.importData.length != 0) this.csvImportReady = true
                } catch (err) {
                    this.$toast.error(err);
                }
                this.csvParseLoading = false
            },
            arrayToData(array) {
                for (let i = 1; i < array.length; i++) {
                    this.importData.push({
                        request: array[i][0],
                        text: array[i][1],
                        file: array[i][2],
                        data: array[i][3],
                        message_type: array[i][4],
                        hold: parseInt(array[i][5]),
                        sensitive: parseInt(array[i][6]),
                    })
                }
            },
            papaParseAsync(file) {
                return new Promise((resolve, reject) => {
                    this.$papa.parse(file, {
                        header: false,
                        complete(results) {
                            resolve(results.data)
                        },
                        error(err, ) {
                            reject(err)
                        }
                    })
                })
            },
            async addContact() {
                this.csvParseLoading = true
                try {
                    let response = await this.$store.dispatch("autoreply_create_bulk", {
                        device_id: parseInt(this.form.device),
                        data: this.importData
                    });
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "importExport");
                    this.$root.$emit("refresh_table", true);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.csvParseLoading = false
            },
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn-spinner {
        line-height: 1rem !important;
    }
</style>